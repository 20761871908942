import { useLazyQuery } from "@apollo/client";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  NativeSelect,
  TextField,
  Typography
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { PRODUCT_SELECTION } from "../../GraphQL";
import { FETCH_PRODUCT_SELECTION } from "../../lib/constants";
import { sortAlphabeticallyByName } from "../../lib/utils";

const userTypeData = [
  {
    value: "INTERNAL",
    displayValue: "Internal"
  },
  {
    value: "PARTNER",
    displayValue: "Partner"
  }
];

const routeToMarket = [
  {
    value: "Direct",
    displayValue: "Direct"
  },
  {
    value: "Indirect",
    displayValue: "Indirect"
  }
];

const clients = [
  {
    value: "iqintegrated",
    displayValue: "IQ-Integrated"
  }
];

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "80%",
    height: "80%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  containerHeight: {
    height: "100%",
    backgroundColor: "white",
    paddingLeft: "49px",
    overflow: "auto"
  },
  containerItem: {
    // height: '100%'
  },
  item: {
    height: "10%"
  },
  formControl: {
    margin: "theme.spacing(1)",
    width: "100%",
    maxWidth: "250px",
    paddingBottom: "5px"
  },
  buttonContainer: {
    textAlign: "right",
    marginRight: "70px !important"
  },
  buttonPadding: {
    marginLeft: "10px !important"
  }
}));

const languages = [
  { code: "en", name: "English" },
  { code: "cs_CZ", name: "Czech" },
  { code: "de", name: "German" },
  { code: "el_GR", name: "Greek" },
  { code: "es", name: "Spanish" },
  { code: "fr", name: "French" },
  { code: "hu_HU", name: "Hungarian" },
  { code: "in_ID", name: "Indonesian" },
  { code: "it", name: "Italian" },
  { code: "ko_KR", name: "Korean" },
  { code: "nl_NL", name: "Dutch" },
  { code: "pl_PL", name: "Polish" },
  { code: "ru_RU", name: "Russian" },
  { code: "sk_SK", name: "Slovak" },
  { code: "th_TH", name: "Thai" },
  { code: "tr_TR", name: "Turkish" },
  { code: "vi_VN", name: "Vietnamese" },
  { code: "zh_CN", name: "Chinese" },
  { code: "zh_TW", name: "Mandarin Chinese" }
];

const BmiSimulationModel = props => {
  const dispatch = useDispatch();
  const prodSelectionsData = useSelector(
    state => state.productSelector.prodSelectionData
  );
  const [selectedRegion, setSelectedRegion] = useState();
  const [countries, setCountries] = useState([]);
  const [selectedLang, setSelectedLang] = useState(
    localStorage.getItem("i18nextLng")
  );
  const { t, i18n } = useTranslation();

  useEffect(() => {
    productSelectionData();
    productSelectionDataPricingGeo();
  }, []);

  function setCountryOnmount() {
    if (state.configRegion) {
      setSelectedRegion(state.configRegion);
    }
  }

  // priceing Geo country start here //
  const [countryData, setCountryData] = useState();
  const [countryList, setCountryList] = useState();
  useEffect(() => {
    const country = [];
    if (countryData && countryData.length !== 0) {
      for (let keys in countryData) {
        countryData[keys].countries.map(cnty => country.push(cnty));
      }
      setCountryList(country);
    }
  }, [countryData]);

  const countryOptions = countryList
    ? countryList.sort(sortAlphabeticallyByName)
    : [];
  const [productSelectionDataPricingGeo] = useLazyQuery(PRODUCT_SELECTION, {
    fetchPolicy: "no-cache",
    onCompleted({ productSelection }) {
      const priceDecDetails = productSelection.PriceDescriptor;
      productSelection = productSelection.ProductSelectionData;
      productSelection["regions"] = sortArray(
        objectToArray(productSelection.regions)
      );
      const productData = {
        productSelection: productSelection
      };
      dispatch({
        type: FETCH_PRODUCT_SELECTION,
        payload: productData
      });
      setCountryOnmount();
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        console.log(graphQLErrors);
      }
      if (networkError) {
        console.log(networkError);
      }
    }
  });

  /// priceing Geo country end here ///

  const [productSelectionData] = useLazyQuery(PRODUCT_SELECTION, {
    fetchPolicy: "no-cache",
    onCompleted({ productSelection }) {
      const countryDt =
        productSelection.ProductSelectionData.regionLookupDataMap;
      setCountryData(countryDt);
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        console.log(graphQLErrors);
      }
      if (networkError) {
        console.log(networkError);
      }
    }
  });

  useEffect(() => {
    let countries = null;
    if (selectedRegion) {
      if (prodSelectionsData) {
        if (prodSelectionsData.regionLookupDataMap[selectedRegion]) {
          if (
            prodSelectionsData.regionLookupDataMap[selectedRegion].countries
          ) {
            countries =
              prodSelectionsData.regionLookupDataMap[selectedRegion]
                .countries &&
              prodSelectionsData.regionLookupDataMap[selectedRegion].countries;
          }
        }
      }
    }

    setCountries(sortArray(countries));
    setState({
      ...state,
      countryCode: countries && countries.length ? countries[0].code : "",
      pricingGeo: countries && countries.length ? countries[0].code : ""
    });
  }, [selectedRegion]);

  const sortArray = array => {
    if (array && array.length) {
      array.sort(function (a, b) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });
    }
    return array;
  };
  const objectToArray = data => {
    let dataArray = [];
    Object.keys(data).map(key => {
      const obj = {
        code: key,
        name: data[key]
      };
      dataArray.push(obj);
    });

    return dataArray;
  };
  const classes = useStyles();
  const { onCancel, history } = props;
  const [state, setState] = useState({
    countryCode: "US",
    pricingGeo: "US",
    configRegion: "NA",
    currencyCode: "USD",
    incoterms: "DDP",
    purchaseAgreement: "",
    productLine: "",
    userId: "shashikantk",
    userType: "INTERNAL",
    userEmail: "shashikant.kalaskar@hp.com",
    companyId: "HPI",
    productCategory: "compute",
    isCustomBandedFlag: false,
    isPartnerPortalFlag: false,
    isDisplayProdCountry: false,
    isManageConfig: false,
    resellerId: "",
    distributorId: "",
    routeToMarket: "Direct",
    mDCPOrgID: "99",
    isStandaloneAccess: false,
    client: "iqintegrated",
    visibility: {},
    saveConfigurationLabel: ""
  });

  const handleChange = (e, selectedField) => {
    if (selectedField === "configRegion") {
      if (e.target.value === "WW") {
        return;
      }
      setSelectedRegion(e.target.value);
    }
    setState({
      ...state,
      [selectedField]:
        selectedField === "isStandaloneAccess"
          ? e.target.checked
          : e.target.value
      //   isDisplayProdCountry: selectedField === 'userType' && e.target.value === 'INTERNAL' ? true : false
    });
  };

  const handleLanguage = e => {
    setSelectedLang(e.target.value);
    localStorage.setItem("i18nextLng", e.target.value);
    i18n.changeLanguage();
  };

  const handleSubmit = () => {
    localStorage.setItem("bmiData", JSON.stringify(state));
    localStorage.setItem("isStandalone", false);
    history.push("/productselection");
  };

  return (
    <Grid container xs={12} className={classes.containerHeight}>
      <Grid container item xs={12} alignItems="center" justify="center">
        <Typography variant="h4" color="primary">
          BMI Tool Simulation
        </Typography>
      </Grid>
      <Grid container item xs={12} md={6} className={classes.containerItem}>
        <Grid container item xs={12} className={classes.item}>
          <Grid item xs={6}>
            <InputLabel>Region</InputLabel>
          </Grid>
          <Grid item xs={6}>
            <FormControl className={classes.formControl}>
              <NativeSelect
                value={state.configRegion}
                onChange={e => handleChange(e, "configRegion")}
              >
                {prodSelectionsData &&
                  prodSelectionsData.regions &&
                  prodSelectionsData.regions.map((region, i) => (
                    <option value={region.code} key={region.code}>
                      {region.name}
                    </option>
                  ))}
              </NativeSelect>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <InputLabel>Country</InputLabel>
          </Grid>
          <Grid item xs={6}>
            <FormControl className={classes.formControl}>
              <NativeSelect
                value={state.countryCode}
                onChange={e => handleChange(e, "countryCode")}
              >
                {!selectedRegion && <option>Select Region</option>}
                {countries &&
                  countries.map((country, i) => (
                    <option
                      value={country.code}
                      id={country.code}
                      data-country={country.name}
                      key={country.code}
                    >
                      {" "}
                      {country.name}
                    </option>
                  ))}
              </NativeSelect>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <InputLabel>Pricing GEO</InputLabel>
          </Grid>
          <Grid item xs={6}>
            <FormControl className={classes.formControl}>
              <NativeSelect
                value={state.pricingGeo}
                onChange={e => handleChange(e, "pricingGeo")}
              >
                {/* {countryList && countryList.map((country,i) => (
                                    <option value={country.code}>{country.name}</option>
                                ))} */}
                {!selectedRegion && <option>Select Region</option>}
                {countries &&
                  countries.map((country, i) => (
                    <option
                      value={country.code}
                      id={country.code}
                      data-country={country.name}
                      key={country.code}
                    >
                      {" "}
                      {country.name}
                    </option>
                  ))}
              </NativeSelect>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <InputLabel>Currency</InputLabel>
          </Grid>
          <Grid item xs={6}>
            <FormControl className={classes.formControl}>
              <FormControl className={classes.formControl}>
                <TextField
                  onChange={e => handleChange(e, "currencyCode")}
                  type="text"
                  value={state.currencyCode}
                  defaultValue="USD"
                ></TextField>
              </FormControl>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <InputLabel>Inco Terms</InputLabel>
          </Grid>
          <Grid item xs={6}>
            <FormControl className={classes.formControl}>
              <TextField
                onChange={e => handleChange(e, "incoterms")}
                type="text"
                value={state.incoterms}
                defaultValue="DF"
              ></TextField>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <InputLabel>Purchase Agreement</InputLabel>
          </Grid>
          <Grid item xs={6}>
            <FormControl className={classes.formControl}>
              <TextField
                onChange={e => handleChange(e, "purchaseAgreement")}
                type="text"
                value={state.purchaseAgreement}
                defaultValue=""
              ></TextField>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <InputLabel>Product Line</InputLabel>
          </Grid>
          <Grid item xs={6}>
            <FormControl className={classes.formControl}>
              <TextField
                onChange={e => handleChange(e, "productLine")}
                type="text"
                value={state.productLine}
                defaultValue=""
              ></TextField>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <InputLabel>Language</InputLabel>
          </Grid>
          <Grid item xs={6}>
            <FormControl className={classes.formControl}>
              <NativeSelect value={selectedLang} onChange={handleLanguage}>
                {languages &&
                  languages.length &&
                  languages.map((lang, i) => (
                    <option value={lang.code} key={lang.code}>
                      {lang.name}
                    </option>
                  ))}
              </NativeSelect>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} md={6}>
        <Grid container item xs={12} className={classes.item}>
          <Grid item xs={6}>
            <InputLabel>User ID</InputLabel>
          </Grid>
          <Grid item xs={6}>
            <FormControl className={classes.formControl}>
              <TextField
                onChange={e => handleChange(e, "userId")}
                type="text"
                value={state.userId}
                defaultValue=""
              ></TextField>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <InputLabel>User Type</InputLabel>
          </Grid>
          <Grid item xs={6}>
            <FormControl className={classes.formControl}>
              <NativeSelect
                value={state.userType}
                onChange={e => handleChange(e, "userType")}
              >
                {userTypeData.map((userT, index) => (
                  <option key={index} value={userT.value}>
                    {userT.displayValue}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <InputLabel>User Email</InputLabel>
          </Grid>
          <Grid item xs={6}>
            <FormControl className={classes.formControl}>
              <TextField
                onChange={e => handleChange(e, "userEmail")}
                type="text"
                value={state.userEmail}
                defaultValue="shashikant.kalaskar@hp.com"
              ></TextField>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <InputLabel>Company ID</InputLabel>
          </Grid>
          <Grid item xs={6}>
            <FormControl className={classes.formControl}>
              <TextField
                onChange={e => handleChange(e, "companyId")}
                type="text"
                value={state.companyId}
                defaultValue="HPI"
              ></TextField>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <InputLabel>Distributor ID</InputLabel>
          </Grid>
          <Grid item xs={6}>
            <FormControl className={classes.formControl}>
              <TextField
                onChange={e => handleChange(e, "distributorId")}
                type="text"
                value={state.distributorId}
                defaultValue=""
              ></TextField>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <InputLabel>RouteToMarket</InputLabel>
          </Grid>
          <Grid item xs={6}>
            <FormControl className={classes.formControl}>
              <NativeSelect
                value={state.routeToMarket}
                onChange={e => handleChange(e, "routeToMarket")}
              >
                {routeToMarket.map((rtm, index) => (
                  <option key={index} value={rtm.value}>
                    {rtm.displayValue}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <InputLabel>MDCP Org ID/Customer ID</InputLabel>
          </Grid>
          <Grid item xs={6}>
            <FormControl className={classes.formControl}>
              <TextField
                onChange={e => handleChange(e, "mDCPOrgID")}
                type="text"
                value={state.mDCPOrgID}
                defaultValue=""
              ></TextField>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <InputLabel>Reseller ID</InputLabel>
          </Grid>
          <Grid item xs={6}>
            <FormControl className={classes.formControl}>
              <TextField
                onChange={e => handleChange(e, "resellerId")}
                type="text"
                value={state.resellerId}
                defaultValue=""
              ></TextField>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <InputLabel>Is Standalone Access</InputLabel>
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              disabled
              control={
                <Checkbox
                  name="isStandaloneAccess"
                  checked={state.isStandaloneAccess}
                  color="primary"
                  size="small"
                  onClick={e => handleChange(e, "isStandaloneAccess")}
                />
              }
              label=""
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel>Client</InputLabel>
          </Grid>
          <Grid item xs={6}>
            <FormControl className={classes.formControl}>
              <NativeSelect
                value={state.client}
                onChange={e => handleChange(e, "client")}
              >
                {clients.map((client, index) => (
                  <option key={index} value={client.value}>
                    {client.displayValue}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          </Grid>
          <br />
          <br />
          <br />
          <br />
          <br />
          <Grid item xs={12} className={classes.buttonContainer}>
            <Button variant="contained" color="primary" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonPadding}
              onClick={() => handleSubmit()}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default withRouter(BmiSimulationModel);
