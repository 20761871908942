import { createMuiTheme } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";
import React from "react";

export const HPSnackbarTheme = createMuiTheme({
  maxSnack: 4,
  anchorOrigin: {
    vertical: "top",
    horizontal: "left"
  },
  iconVariant: {
    success: (
      <CheckCircleIcon
        style={{
          fontSize: "20px",
          opacity: 0.9,
          marginRight: "10px",
          color: "white"
        }}
      />
    ),
    error: (
      <ErrorIcon
        style={{
          fontSize: "20px",
          opacity: 0.9,
          marginRight: "10px",
          color: "white"
        }}
      />
    ),
    warning: (
      <WarningIcon
        style={{
          fontSize: "20px",
          opacity: 0.9,
          marginRight: "10px",
          color: "orange"
        }}
      />
    ),
    info: (
      <InfoIcon
        style={{
          fontSize: "20px",
          opacity: 0.9,
          marginRight: "10px",
          color: "white"
        }}
      />
    )
  },
  contentprops: {
    "aria-describedby": "message-id"
  }
});

export const HPMuiTheme = createMuiTheme({
  palette: {
    primary: { main: "#0096d6" },
    secondary: { main: "#d7410b" }
  },
  overrides: {
    MuiTableCell: {
      stickyHeader: {
        "z-index": 99
      }
    },
    MuiTableBody: {
      root: {
        "& .MuiTableCell-root.currencyWithPercentageStyle": {
          "min-width": "150px",
          "& svg": {
            "padding-left": "10px",
            "vertical-align": "middle !important"
          }
        }
      }
    }
  }
});

export const snackbarStyle = {
  success: { backgroundColor: "#0171ad" },
  error: { backgroundColor: "#d7410b" },
  warning: { backgroundColor: "#ffa500" },
  info: { backgroundColor: "#0171ad" }
};
