import React from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1, 2, 1),
    borderRadius: "10px",
  },
  buttonCls: {
    float: "right",
    marginTop: "1em",
    fontSize: theme.spacing(2),
    color: "dodgerblue",
  },
  warningIcon: {
    marginLeft: "10px",
    color: "red",
    position: "absolute",
  },
}));

const WarningPopup = (props) => {
  const classes = useStyles();
  const { open, headerTitle, handleClose, handleBtnClick, children } = props;

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      disableBackdropClick
      disableEscapeKeyDown
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <h2>
            {headerTitle}
            <ErrorOutlineOutlinedIcon
              fontSize="large"
              className={classes.warningIcon}
            ></ErrorOutlineOutlinedIcon>
          </h2>
          {/* <div id="horizontal-line-first-half"></div>
            <div id="horizontal-line-second-half"></div> */}
          {children}
          <Button
            className={classes.buttonCls}
            color="primary"
            onClick={() => handleBtnClick()}
          >
            CONTINUE
          </Button>
        </div>
      </Fade>
    </Modal>
  );
};

export default WarningPopup;
