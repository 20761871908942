import { Button, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Cookie from "js-cookie";
import jwt_decode from "jwt-decode";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { USER_LOGIN_REQUEST } from "../actions/Types";
import { logout } from "../actions/userActions";

const LandingPage = props => {
  const token = Cookie.get("access_token");

  const [isLoading, setIsLoading] = useState(false);
  const [showLoginButton, setShowLoginButton] = useState(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!token) {
      setShowLoginButton(true);
    }
  }, []);

  React.useEffect(() => {
    if (token) {
      const userInfo = jwt_decode(token);
      Cookie.set("userInfo", JSON.stringify(userInfo));
      if (userInfo.exp > new Date().getTime() / 1000) {
        props.history.push("/dashboard");
      } else {
        logout();
      }
    } else {
      logout();
    }
  }, [token]);

  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary
    },
    button: {
      boxShadow: "none",
      textTransform: "none",
      fontSize: 16,
      padding: "6px 12px !important",
      border: "1px solid",
      lineHeight: 1.5,
      backgroundColor: "#0063cc !important",
      borderColor: "#0063cc !important",
      color: "#ffffff !important",
      margin: "0px 12px !important",
      flex: "0.4",
      "&:hover": {
        backgroundColor: "#0069d9 !important",
        borderColor: "#0062cc !important",
        boxShadow: "none"
      },
      "&:active": {
        boxShadow: "none",
        backgroundColor: "#0062cc !important",
        borderColor: "#005cbf !important"
      },
      "&:focus": {
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)"
      }
    },
    content: {
      paddingLeft: "490px",
      paddingRight: "520px"
    },
    landing: {
      paddingTop: "10px",
      paddingBottom: "20px",
      boxSizing: "border-box",
      border: "1px solid lightskyblue",
      color: "black",
      borderRadius: "10px",
      boxShadow: "0 0 135px 114px rgba(0,0,0,0.06)",
      overflow: "hidden",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translateX(-50%) translateY(-50%)",
      width: "400px"
    },
    logIn: {
      display: "flex",
      justifyContent: "center",
      fontSize: "20px",
      padding: "20px 8px 8px 0px"
    },
    loginhead: {
      fontSize: "40px",
      padding: "20px",
      textAlign: "center"
    }
  }));

  const handleHPIDLogin = () => {
    onLogin();
    window.location.href = process.env.REACT_APP_HPID_LOGIN_URL;
  };

  const handleHPUIDLogin = () => {
    onLogin();
    window.location.href = process.env.REACT_APP_HPUID_LOGIN_URL;
  };

  const onLogin = () => {
    dispatch({ type: USER_LOGIN_REQUEST });
    setIsLoading(true);
  };

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.landing}>
          <div className={classes.loginhead}>IQ Login</div>
          {isLoading ? (
            <div align="center">
              <CircularProgress />
            </div>
          ) : (
            <form action="" method="post">
              <div textalign="center" className={classes.logIn}>
                {process.env.REACT_APP_ENVIRONMENT !== "production" ? (
                  <Button
                    size="medium"
                    variant="outlined"
                    color="primary"
                    className={classes.button}
                    style={{"display": (showLoginButton) ? "" : "none"}}
                    onClick={handleHPIDLogin}
                  >
                    HP ID Login
                  </Button>
                ) : null}
                <Button
                  size="medium"
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                  style={{"display": (showLoginButton) ? "" : "none"}}
                  onClick={handleHPUIDLogin}
                >
                  HP UID Login
                </Button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(LandingPage);
